import { useSessionStorage } from "@vueuse/core";

export const usePostDraft = (clearHook?: () => {}) => {
  const storedMessage = useSessionStorage("post-draft", () => "");
  const clearMessage = () => {
    storedMessage.value = "";
    clearHook?.();
  };

  return {
    storedMessage,
    clearMessage,
  };
};
